import React from 'react'
import ReactModal from 'react-modal';
import "../Modall/modall.css";
import modallerror_logo from "../../assets/img/logo.png"

const Modallerror = ({ closeModal }) => {
    return (
        <div>

            <ReactModal  isOpen={true} style={{ overlay: { height: '310px', width: '500px', top: "15%", left: "25%", backgroundColor: "#008bd0", border: "none"  }, zIndex:999 } } onRequestClose={closeModal}>
                <div className='modal_center'>
                    <img className='img-fluid modall_img' src={modallerror_logo}></img><br />
                    <h3 className="mt-2 mb-2 oops">Oops..</h3>
                    <h3 className='text-center fw-bold'>Something went wrong! <br /> Please Try again later</h3>

                    <button className='btn btn-primary text-center mt-3' onClick={closeModal}>Close</button>
                </div>
            </ReactModal>
        </div>
    )
}

export default Modallerror




