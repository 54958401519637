import React, { useState, useEffect } from 'react';
import { getAuth, createUserWithEmailAndPassword, sendPasswordResetEmail, sendEmailVerification, signInWithEmailAndPassword } from "firebase/auth";
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';
import { toast } from 'react-toastify';
import Navbar from '../../../Components/Navbar/Navbar';
import Footer from '../../../Components/Footer/Footer';
import firestore from '../../../firebase';
import { Icon } from '@iconify/react';

const firebaseConfig = {
  apiKey: "AIzaSyC_eOHkIlB8eROEtcOra5RD7MmQwYYwsTY",
  authDomain: "mlc-websites.firebaseapp.com",
  databaseURL: "https://mlc-websites-default-rtdb.firebaseio.com",
  projectId: "mlc-websites",
  storageBucket: "mlc-websites.appspot.com",
  messagingSenderId: "291991765248",
  appId: "1:291991765248:web:aaceca21e0d38fcd4eb3e5",
  measurementId: "G-430Y0PMCVZ"
};

  const app = initializeApp(firebaseConfig);
  const db = getDatabase(app);
  const contactsRef = ref(db);
  const auth = getAuth();
  const Chiropractor = () => {

  const [data, setData] = useState(null);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [data1, setData1] = useState([]);
  const [password, setPassword] = useState('');

  const [newval, setNewval] = useState('');
      
  useEffect(() => {
    const listener = onValue(contactsRef, (snapshot) => {
      const data = snapshot.val();
      setData(data);

    });
    return () => {
      listener();
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await firestore.collection('data').get();
        const fetchedData = snapshot.docs.map(doc => doc.data());
        setData1(fetchedData);
        console.log(fetchedData)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handlechange = (event) =>{
    const temp= event.target.value;
    setNewval(temp)
  }

  const currentUser = auth.currentUser;
  const currentYear = new Date().getFullYear();
  console.log(data1)

    return (
        <div>
      <Navbar />
      < div style={{marginTop:"100px", backgroundColor:"#eef1f3"}}>
    
        <section id="services" className="services pt-5">
          <div className="container">
         <div className='row'>
            <div className='new_input_div'>
            <input type='text' placeholder='Search Chiropractor' className='' onChange={handlechange}></input> <button className=''>Search</button>
                </div>         </div>
            <div className="" data-aos="fade-left">
              <div className="swiper-container swiper-initialize " data-aos="fade-up" data-aos-delay="100">
           {
            data1==null? <h1>Fetching Data</h1> :
            <div className=' row '>
            {data1 && Object.values(data1).filter((val)=>(val.firstName?(val.firstName).toLowerCase().includes(newval.toLowerCase()):null)).map((item) => (
              (item.category=="Chiropractor") ? (<div className="swiper-slide cardnewwidth">
                <div className="icon-box" data-aos="zoom-in" data-aos-delay="50" >

                  <small className='small1'>{item.primarySpecialization}</small>
                  {item.otherSpecializations=="NA"? <p className='small2' style={{visibility:"hidden"}}>item.otherSpecializations</p>:item.otherSpecializations==""? item.otherSpecializations: <small className='small2'>{item.otherSpecializations.map(item=><small  className=''>{item}<br /></small>)}</small>}
             
                  {currentUser === null ? (
                    <div className="non_blur_item">
                  <p  className="name-red mb-2"><Icon icon="bi:person" color="red" width="18" height="18" /> {item.firstName} {item.middleName} {item.lastName}</p>
                            {(item.practiceStartedIn==""||item.practiceStartedIn=="NA"||item.practiceStartedIn=="na" || item.practiceStartedIn==null)? <p className="mb-2" style={{visibility:"hidden"}}> <b>NA </b></p> : <p className="mb-2"><Icon icon="mdi:work-outline" color="#424242" width="16" height="16" /> <b>{currentYear- item.practiceStartedIn}+ </b> Years of Experience</p>}
                    <p className="mb-2"><Icon icon="solar:phone-outline" color="#424242" width="16" height="16" /> {item.phone}</p>
                  
                    <p className="mb-2 " style={{height:"40px"}} ><Icon icon="la:address-card" color="#424242" width="16" height="16" /> {item.contactAddress}</p>
                    <p className="mb-2 "> <Icon icon="mdi:home-city-outline" color="#424242" width="16" height="16" /> {item.city}, {item.state}</p>
                      <div className="rating-box">
                        <div className="stars">
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                        </div>
                      </div>

                    </div>) : (<div className='non_blur_item'>
                    <p  className="name-red mb-2"><Icon icon="bi:person" color="red" width="18" height="18" /> {item.firstName} {item.middleName} {item.lastName}</p>
                            {(item.practiceStartedIn==""||item.practiceStartedIn=="NA"||item.practiceStartedIn=="na" || item.practiceStartedIn==null)? <p className="mb-2" style={{visibility:"hidden"}}> <b>NA </b></p> : <p className="mb-2"><Icon icon="mdi:work-outline" color="#424242" width="16" height="16" /> <b>{currentYear- item.practiceStartedIn}+ </b> Years of Experience</p>}
                    <p className="mb-2"><Icon icon="solar:phone-outline" color="#424242" width="16" height="16" /> {item.phone}</p>
                  
                    <p className="mb-2 " style={{height:"40px"}} ><Icon icon="la:address-card" color="#424242" width="16" height="16" /> {item.contactAddress}</p>
                    <p className="mb-2 "> <Icon icon="mdi:home-city-outline" color="#424242" width="16" height="16" /> {item.city}, {item.state}</p>
                      <div className="rating-box">
                        <div className="stars">
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>) : (item.primarySpecialization=="Chiropractor") ? (<div className="swiper-slide cardnewwidth">
                <div className="icon-box" data-aos="zoom-in" data-aos-delay="50" >
                  <small className='small1'>{item.primarySpecialization}</small>
                  {item.otherSpecializations=="NA"? <p className='small2' style={{visibility:"hidden"}}>item.otherSpecializations</p>:item.otherSpecializations==""? item.otherSpecializations: <small className='small2'>{item.otherSpecializations.map(item=><small  className=''>{item}<br /></small>)}</small>}
                  {currentUser === null ? (
                    <div className="non_blur_item">
               <p  className="name-red mb-2"><Icon icon="bi:person" color="red" width="18" height="18" /> {item.firstName} {item.middleName} {item.lastName}</p>
                            {(item.practiceStartedIn==""||item.practiceStartedIn=="NA"||item.practiceStartedIn=="na" || item.practiceStartedIn==null)? <p className="mb-2" style={{visibility:"hidden"}}> <b>NA </b></p> : <p className="mb-2"><Icon icon="mdi:work-outline" color="#424242" width="16" height="16" /> <b>{currentYear- item.practiceStartedIn}+ </b> Years of Experience</p>}
                    <p className="mb-2"><Icon icon="solar:phone-outline" color="#424242" width="16" height="16" /> {item.phone}</p>
                  
                    <p className="mb-2 " style={{height:"40px"}} ><Icon icon="la:address-card" color="#424242" width="16" height="16" /> {item.contactAddress}</p>
                    <p className="mb-2 "> <Icon icon="mdi:home-city-outline" color="#424242" width="16" height="16" /> {item.city}, {item.state}</p>
                      <div className="rating-box">
                        <div className="stars">
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                        </div>
                      </div>
                 
                    </div>) : (<div className='non_blur_item'>
                    <p  className="name-red mb-2"><Icon icon="bi:person" color="red" width="18" height="18" /> {item.firstName} {item.middleName} {item.lastName}</p>
                            {(item.practiceStartedIn==""||item.practiceStartedIn=="NA"||item.practiceStartedIn=="na" || item.practiceStartedIn==null)? <p className="mb-2" style={{visibility:"hidden"}}> <b>NA </b></p> : <p className="mb-2"><Icon icon="mdi:work-outline" color="#424242" width="16" height="16" /> <b>{currentYear- item.practiceStartedIn}+ </b> Years of Experience</p>}
                    <p className="mb-2"><Icon icon="solar:phone-outline" color="#424242" width="16" height="16" /> {item.phone}</p>
                  
                    <p className="mb-2 " style={{height:"40px"}} ><Icon icon="la:address-card" color="#424242" width="16" height="16" /> {item.contactAddress}</p>
                    <p className="mb-2 "> <Icon icon="mdi:home-city-outline" color="#424242" width="16" height="16" /> {item.city}, {item.state}</p>
                      <div className="rating-box">
                        <div className="stars">
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                        </div>
                      </div>
               
                    </div>
                  )}
                </div>
              </div>)

                : <>

                </>

            ))}
          </div>
           }
              </div>
            </div>

          </div>

        </section >
      </div >
      <Footer />
    </div>
    )
}

export default Chiropractor












  







  




