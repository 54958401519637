import React from 'react'
import "./testimonial.css";
import { useState, useEffect } from 'react';
import client from '../../../sanity';

const Users = () => {

    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          const query = '*[_type == "testimonial"]'; 
          const result = await client.fetch(query);
          setData(result);
         
        };
    
        fetchData();
      }, []);


  return (
    <div>
        <section id="testimonials" className="testimonials ">
          <div className="container">
          <div className='row '>
          <div className='text-center testimonial__heading'>
                    <h2> <b>Testimonials </b></h2>
                </div>

             {
                data.slice(data.length-4,data.length).map((item)=>(
                   
                    <div className='col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center align-items-center text-center'>
                    <figure className="snip1157">
                        <blockquote> {item.description} 
                  
                            <div className="arrow"></div>
                        </blockquote>
                      
                        <div className="author">
                            <h5>{item.name}</h5>
                        </div>
                    </figure>
                </div>
                ))
             }

                </div>
            </div>
    </section>
</div>        
)
}

export default Users