import React from 'react'
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer"
import Newsletter from '../Newsletter/Newsletter';
import { Link } from 'react-router-dom';
import "./extra.css";
import Query from '../Query/Query';

const Thankyou = () => {
    return (
        <div id="thankyou" className='thankyou'>
            <Navbar />
            <Query />
            <div className='container '>
                <section className='thankyou-inner' >
                    <h2> <span>Thankyou ! </span> for contacting us.</h2>
                    <p>Our team will contact you soon.</p>
                    <p> <Link to="/" className='thankyou-link'>Click here</Link> To return to the Main page.</p>
                </section>
            </div>
            <Newsletter />
            <Footer />
        </div>
    )
}

export default Thankyou
