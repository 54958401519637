
import firebase from 'firebase/compat/app'; // Notice the '/compat' in the import path
import 'firebase/compat/firestore'; 


firebase.initializeApp({
  apiKey: "AIzaSyC_eOHkIlB8eROEtcOra5RD7MmQwYYwsTY",
  authDomain: "mlc-websites.firebaseapp.com",
  databaseURL: "https://mlc-websites-default-rtdb.firebaseio.com",
  projectId: "mlc-websites",
  storageBucket: "mlc-websites.appspot.com",
  messagingSenderId: "291991765248",
  appId: "1:291991765248:web:aaceca21e0d38fcd4eb3e5",
  measurementId: "G-430Y0PMCVZ"
});

// Get a Firestore instance
const firestore = firebase.firestore();

export default firestore;


