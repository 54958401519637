import React, { useRef, useState } from 'react'
import "./newsletter.css";
import emailjs from '@emailjs/browser';
import Modall from "../../Components/Modall/Modall";
import Modallerror from "../../Components/Modall/Modallerror";

const Newsletter = () => {

    const [showModal, setShowModal] = useState(false);
    const [showModalError, setShowModalError] = useState(false);
    const openModal = () => {
        setShowModal(true);
      };
    
      const closeModal = () => {
        setShowModal(false);
        setShowModalError(false);
      };
    
      const form = useRef();
    const sendEmail = (e) => {
      e.preventDefault(); 
  
      emailjs.sendForm('service_6cuir0f', 'newsletter', form.current, '4Y94BulGaL_N4yMfK')
        .then((result) => {
          setShowModal(true)
          setTimeout(function(){
            window.location.reload();
         }, 15000);
        }, (error) => {
          setShowModalError(true)
          setTimeout(function(){
            window.location.reload();
         }, 15000);
        });
    };

   

    return (
        <div>

{
                      <div>
                      {showModal ? (
                        <Modall closeModal={closeModal}  /> 
                      ) : ""}
                    </div>
                    }

{
                      <div>
                      {showModalError ? (
                        <Modallerror closeModal={closeModal}  /> 
                      ) : ""}
                    </div>
                    }

            <div className="footer-newsletter">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 text-center">
                            <h2 className="fw-semibold">Subscribe for more offers</h2>
                        </div>
                        <div className="col-lg-6">
                            <form ref={form} onSubmit={sendEmail}>
                                <input type="email" name="email" placeholder="Enter Your Email" required />
                                    <input type="submit" value="Subscribe" />
                                    </form>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
             )
}

            export default Newsletter