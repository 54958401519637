import React from 'react'
import Binoculars_icon from "../../assets/img/icons/binoculars.png";
import Achievement_icon from "../../assets/img/icons/achievement.png";

const Vision = () => {
  return (
    <div style={{ backgroundColor: " #e5f1f8" }}>
      <div className='container  vision'>
        <div className='row pt-3 pb-5'>


          <div className='col-md-6 col-sm-12 inner__col' >
            <div className='card'>
              <div>
                <img src={Binoculars_icon} className='img-fluid icon__about2' alt="Binoculars_icon"></img>
              </div>
              <div>
                <h4>Our Vision</h4>
                <p> AccidentMATE's vision is to help every accident victim obtain the best medical care outcome and legal help they deserve.</p>
              </div>
            </div>
          </div>

          <div className='col-md-6 col-sm-12 inner__col '>
          <div className='card'>
              <div>
                <img src={Achievement_icon} className='img-fluid icon__about' alt="Achievement_icon"></img>
              </div>
              <div>
                <h4>Our Mission</h4>
                <p>AccidentMATE aims to bring together medical and legal experts to help med-legal cases obtain a speedy and optimal outcome while also streamlining real-time information flow among the different parties.</p>
              </div>
            </div>
          </div>

       


        </div>
      </div>
    </div>
  )
}

export default Vision