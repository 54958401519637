import React from 'react'

const Map = () => {
    return (
        <div>
            <section className="map py-0">
                <div className="container-fluid px-0">
                    <div className="row">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3319.365677432453!2d-117.8393278854656!3d33.6994854807034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcdea3e19dfb77%3A0x82ec11b3b7df6df5!2s2160%20Barranca%20Pkwy%20%231014%2C%20Irvine%2C%20CA%2092606%2C%20USA!5e0!3m2!1sen!2sin!4v1677219159333!5m2!1sen!2sin" width="100%" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Map