import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import 'firebase/database';
import axios from 'axios';
import Navbar from '../../../Components/Navbar/Navbar';
import Footer from '../../../Components/Footer/Footer';
import "./modal.css";
import firestore from '../../../firebase';
import { Icon } from '@iconify/react';


const firebaseConfig = {
  apiKey: "AIzaSyC_eOHkIlB8eROEtcOra5RD7MmQwYYwsTY",
  authDomain: "mlc-websites.firebaseapp.com",
  databaseURL: "https://mlc-websites-default-rtdb.firebaseio.com",
  projectId: "mlc-websites",
  storageBucket: "mlc-websites.appspot.com",
  messagingSenderId: "291991765248",
  appId: "1:291991765248:web:aaceca21e0d38fcd4eb3e5",
  measurementId: "G-430Y0PMCVZ"
};


const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const contactsRef = ref(db);
const auth = getAuth();



const Avail = (props) => {
  const [searchData, setSearchData] = useState([]);
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('query');

  var city = new URLSearchParams(location.search).get('city');
  const [data, setData] = useState([]);
  const [ipAddress, setIpAddress] = useState();
  const [data1, setData1] = useState([]);


  useEffect(() => {
    const fetchLocation = async () => {
      const response = await axios.get('https://get.geojs.io/v1/ip/geo.json');
      setIpAddress(response.data);
    };
    fetchLocation();

    const listener = onValue(contactsRef, (snapshot) => {
      const data = snapshot.val();
      setData(data);
    });

    return () => {
      listener();
    };
  }, []);

  if (city === "") {
    city = ipAddress?.city;
  }



  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await firestore.collection('data').get();
        const fetchedData = snapshot.docs.map(doc => doc.data());
        setData1(fetchedData);
        console.log(fetchedData)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

console.log(data1)


const filteredData = data1
  ? Object.values(data1).filter((item) => {
      const isCategoryMatch =
        item.category &&
        item.category.toLowerCase().includes(query.toLowerCase());

      const isPrimarySpecializationMatch =
        item.primarySpecialization &&
        item.primarySpecialization
          .toLowerCase()
          .includes(query.toLowerCase());

      const isSecondarySpecializationMatch =
        item.otherSpecializations &&
        item.otherSpecializations.some(
          (specialization) =>
            specialization.toLowerCase().includes(query.toLowerCase())
        );

      const isCityMatch =
        item.city && item.city.toLowerCase().includes(city.toLowerCase());

      return (
        (query ? (isCategoryMatch || isPrimarySpecializationMatch || isSecondarySpecializationMatch) : false) &&
        (city ? isCityMatch : false)
      );
    })
  : [];


  const num = filteredData.length;
  const currentUser = auth.currentUser;


  const currentYear = new Date().getFullYear();


  return (
    <div>
      <Navbar />

      <section id="search-result" className="search-result pt-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center" data-aos="fade-down">
              {
                num == 0 ? <div class="loader"></div>
                  : <h2 className="fw-semibold">{num}  Available in this Location</h2>
              }
              <p className="fw-semibold fs-14"><i className="bi bi-check-circle fs-5"></i> Book appointments with minimum wait-time & verified doctor details</p>
            </div>
          </div>
          <div>
            <div>
            </div>
          </div>
          <div className="row pt-4">
            {
              filteredData.map((item) => (
                <div className="swiper-slide cardnewwidth">
                  <div className="icon-box" data-aos="zoom-in" data-aos-delay="50" >
                    <small style={{ height: "20px" , fontWeight:"600" }}>{item.primarySpecialization}</small><br />
                    {(item.otherSpecializations=="NA" || item.otherSpecializations==null || item.otherSpecializations=="")? <small  style={{visibility:"hidden" }}>item.otherSpecializations</small>: <small className='small2'>{item.otherSpecializations.map(item=><small  className=''>{item}<br /></small>)}</small>}
                    {currentUser === null ? (
                      <div className="non_blur_item">
                          <p  className="name-red mb-2"><Icon icon="bi:person" color="red" width="18" height="18" /> {item.firstName} {item.middleName} {item.lastName}</p>
                               {(item.practiceStartedIn==""||item.practiceStartedIn=="NA"||item.practiceStartedIn=="na" || item.practiceStartedIn==null)? <p className="mb-2" style={{visibility:"hidden"}}> <b>NA </b></p> : <p className="mb-2"><Icon icon="mdi:work-outline" color="#424242" width="16" height="16" /> <b>{currentYear- item.practiceStartedIn}+ </b> Years of Experience</p>}
                          <p className="mb-2"><Icon icon="solar:phone-outline" color="#424242" width="16" height="16" /> {item.phone}</p>
                          
                          <p className="mb-2 " ><Icon icon="la:address-card" color="#424242" width="16" height="16" /> {item.contactAddress}</p>
                          <p className="mb-3 "> <Icon icon="mdi:home-city-outline" color="#424242" width="16" height="16" /> {item.city}, {item.state}</p>
                  
                        <div className="rating-box">
                          <div className="stars">
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                          </div>
                        </div>
                  
                      </div>) : (currentUser.emailVerified == true ?
                        <div className='non_blur_item'>
                  
                          <p  className="name-red mb-2"><Icon icon="bi:person" color="red" width="18" height="18" /> {item.firstName} {item.middleName} {item.lastName}</p>
                               {(item.practiceStartedIn==""||item.practiceStartedIn=="NA"||item.practiceStartedIn=="na" || item.practiceStartedIn==null)? <p className="mb-2" style={{visibility:"hidden"}}> <b>NA </b></p> : <p className="mb-2"><Icon icon="mdi:work-outline" color="#424242" width="16" height="16" /> <b>{currentYear- item.practiceStartedIn}+ </b> Years of Experience</p>}
                          <p className="mb-2"><Icon icon="solar:phone-outline" color="#424242" width="16" height="16" /> {item.phone}</p>
                          
                          <p className="mb-2 "  ><Icon icon="la:address-card" color="#424242" width="16" height="16" /> {item.contactAddress}</p>
                          <p className="mb-2 "> <Icon icon="mdi:home-city-outline" color="#424242" width="16" height="16" /> {item.city}, {item.state}</p>
                          <div className="rating-box">
                            <div className="stars">
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                            </div>
                          </div>
                      
                        </div> :
                  
                        <div className="non_blur_item">
                           <p  className="name-red mb-2"><Icon icon="bi:person" color="red" width="18" height="18" /> {item.firstName} {item.middleName} {item.lastName}</p>
                                {(item.practiceStartedIn==""||item.practiceStartedIn=="NA"||item.practiceStartedIn=="na" || item.practiceStartedIn==null)? <p className="mb-2" style={{visibility:"hidden"}}> <b>NA </b></p> : <p className="mb-2"><Icon icon="mdi:work-outline" color="#424242" width="16" height="16" /> <b>{currentYear- item.practiceStartedIn}+ </b> Years of Experience</p>}
                          <p className="mb-2"><Icon icon="solar:phone-outline" color="#424242" width="16" height="16" /> {item.phone}</p>
                          
                          <p className="mb-2 "  ><Icon icon="la:address-card" color="#424242" width="16" height="16" /> {item.contactAddress}</p>
                          <p className="mb-3 "> <Icon icon="mdi:home-city-outline" color="#424242" width="16" height="16" /> {item.city}, {item.state}</p>
                          <div className="rating-box">
                            <div className="stars">
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                            </div>
                          </div>
                        </div>
                    )}
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Avail;


