import React from 'react'
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer"
import Newsletter from '../Newsletter/Newsletter';
import { Link } from 'react-router-dom';
import "./extra.css";
import Query from '../Query/Query';

const Thankyou = () => {
  return (
    <div id="errorPage" className='errorPage'>
      <Navbar />
      <Query />
      <div className='container '>
      <section className='errorPage-inner' >
       
      <h2> <span>Uh Oh ! </span> This is not the Page you were looking for.</h2>
      <p>Sorry We've led you astray, Now Let's get you back on the track.</p>

     <p> <Link to="/" className='errorPage-link'>Click here</Link></p>
   
      </section>
       
     </div>
     <Newsletter />
      <Footer />
    </div>
  )
}

export default Thankyou
