import React , {useState} from 'react'
import "./navbar.css"
import { getAuth, signOut } from "firebase/auth";
import accidentmate_logo from "../../assets/img/logo.png";
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';

const Navbar = (props) => {
const auth = getAuth();
  const logout = () => {
    signOut(auth).then(() => {
      localStorage.clear();
      alert("Logged Out")
      window.location.reload("/");
    
    }).catch((error) => {
      alert(error)
   
    });
  }

  window.addEventListener('scroll', function () {
    var header = document.getElementById("header");
    header.classList.toggle("scrolled", window.scrollY > 0)
  });

  return (

    <header id="header" className="header">
      <nav className="navbar navbar-expand-lg navbar-light ">
        <div className="container d-flex justify-content-between align-items-center">
          <Link to="/" className='navbar-logo'><img src={accidentmate_logo} alt="Accident-Mate" className="img-fluid nav-img" /></Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
              <Link to="https://organizemed.com/" className=" navbarp" target="_blank">For Doctors</Link>
              </li>
              <li className="nav-item">
              <Link to="https://organizelaw.com/" className=" navbarp" target="_blank">For Attorney</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>



  )
}

export default Navbar