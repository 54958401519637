
import './App.css';
import React, { useEffect, useState } from 'react';

import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Mainn from './Pages/Mainn/Mainn';
import Avail from './Pages/Mainn/Available/Avail';
import About from './Pages/AboutUs/About';
import Contact from './Pages/Contact/Contact';
import TANDC from './Pages/T&C/TANDC';
import Privacypolicy from './Pages/Privacypolicy/Privacypolicy';
import Securitypolicy from './Pages/Securitypolicy/Securitypolicy';
import News from './Pages/Blog/News';
import Singleblog from './Pages/SingleBlogPage/Singleblog';
import Attorney from './Pages/SinglePageUser/Popular/Attorney';
import Chiropractor from './Pages/SinglePageUser/Popular/Chiropractor';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cprivacy from "../src/Pages/CaliforniaPrivacy/Cprivacy"
import ScrollToTop from './ScrollToTop';
import Singlepageuser from './Pages/SinglePageUser/Single';
import Thankyou from './Components/Extra/Thankyou';
import Error from './Components/Extra/Error';
import LandingPage from './Components/LandingPage/LandingPage';


function App() {

  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Mainn />}></Route>
          <Route path='/about' element={<About />}></Route>
          <Route path='/contact' element={<Contact />}></Route>
          <Route path='/terms-and-conditions' element={<TANDC />}></Route>
          <Route path='/security-policy' element={<Securitypolicy />}></Route>
          <Route path='/privacy-policy' element={<Privacypolicy />}></Route>
          <Route path='/news' element={<News />}></Route>
          <Route path='/news/:slugid' element={<Singleblog />}></Route>
          <Route path='/search' element={<Avail />}></Route>
          <Route path='/popular' element={<Singlepageuser />}></Route>
          <Route path='/popular-attorneys' element={<Attorney />}></Route>
          <Route path='/popular-chiropractors' element={<Chiropractor />}></Route>
          <Route path='/california-privacy-notice' element={<Cprivacy />}></Route>
          <Route path='/accident-support' element={<LandingPage />}></Route>
          <Route path='/thankyou' element={<Thankyou />}></Route>
          <Route path='*' element={<Error />}></Route>
        </Routes>
      </Router>
      <ToastContainer />

    </>
  );
}

export default App;
