import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

var dataa = require("./Mock_Data.json");
var dataa_city = require("./City_Data.json");

const Search = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [city, setCity] = useState(''); // Default value for city
  const [state, setState] = useState(''); // Default value for city
  const [items, setItems] = useState('');
  const [ipAddress, setIpAddress] = useState({ city: 'Unknown' }); // Default value for ipAddress
  const [data, setData] = useState([]);
  const [value, setValue] = useState('');
  const [value_city, setValue_city] = useState('');
  const [isVisible, setIsVisible] = useState(true);
  const [ disabled, setDisabled] = useState(true);

  const isButtonDisabled = !(value_city && value);
  const fetchCaliforniaCities = async () => {
    try {
      const response = await axios.get(
        'https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json'
      );
      setData(response.data);
    } catch (error) {
      console.error('Error fetching California cities:', error);
    }
  };

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await axios.get('https://get.geojs.io/v1/ip/geo.json');
        setIpAddress(response.data);
      } catch (error) {
        console.log('Error fetching location:', error);
      }
    };
    fetchLocation();
    fetchCaliforniaCities();
  }, [value_city]);

  const country2 = [...new Set(data.map((item) => (
    (item.country == "United States") ?
      item.subcountry :
      null
  )))];

  const country = [...new Set(data.map((item) => (
    (item.subcountry == state) ?
      item.name :
      null
  )))];

  const onChange = (event) => {
    setValue(event.target.value);
    setSearchQuery(event.target.value);
  };

  const onChange_city = (event) => {
    const cityValue = event.target.value;
    setValue_city(event.target.value);
    setCity(event.target.value);

    if(cityValue.length>=3){
      setDisabled(false)
    }
    else{
      setDisabled(true)
    }
  
  };

  console.log(city);
  console.log(value_city);


  return (
    <div>
      <section id="search-box" className="search-box" data-aos="fade-down">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="search-inner">
                <form className="input-group  p-3">
                  <div className="input-group-btn search-panel">
                    <div className='relative__div'>
                      <input
                        type="text"
                        className=" form-control1"
                        name="x"
                        placeholder="City"
                        value={value_city}
                        onChange={onChange_city}
                        required
                      />
                      <div className='relative__divrow'>
                        {dataa_city
                          .filter((item) =>
                            item.full_name.toLowerCase().startsWith(city.toLowerCase())
                          )
                          .map((item) =>
                            value_city.length > 2 &&
                              value_city.toLowerCase().includes(city.toLowerCase()) ? (
                              <div className='relative__divrow_' id="relative__divrow_" >
                                <span style={{ }}  onClick={() => { setCity(item.full_name); setValue_city(item.full_name) }}>
                              {value_city==item.full_name? null : <p style={ { fontSize: "14px", color: "#008bd0", padding:"2.5px 5px 0px 5px "}}>{item.full_name}</p>}
                                </span>
                              </div>
                            ) : null
                          )}
                      </div>
                    </div>
                  </div>
                  <div className='relative__div'>
                    <input
                      type="text"
                      className="form-control specialization"
                      name="x"
                      placeholder="Attorney, Chiropractor..."
                      value={value}
                      onChange={onChange}
                      required
                      disabled={disabled}
                    />
                    <div className='relative__divrow'>
                      {dataa
                        .filter((item) =>
                          item.full_name.toLowerCase().includes(searchQuery.toLowerCase())
                        )
                        .map((item) =>
                          value.length > 2 && value.toLowerCase().includes(searchQuery.toLowerCase()) ? (
                          
                           ( isVisible==true?(
                              <div className='relative__divrow_' id="relative__divrow_2" onClick={() => { value == null ? localStorage.clear() : localStorage.setItem('Key', item.full_name); setSearchQuery(item.full_name); setValue(item.full_name) }}>
                              <Link to={`/search?query=${searchQuery}&city=${city}`}>
                                <span style={{ fontSize: "14px" }}>
                                {value==item.full_name? null : <p style={ { fontSize: "14px", color: "#008bd0", padding:"2.5px 5px 0px 5px "}}>{item.full_name}</p>}
                                </span>
                              </Link>
                            </div>
                            )
                            :null
                           )
                          ) : null
                        )}
                    </div>
                  </div>
                  <span className="input-group-btn nnnbtn">
                    <Link
                      style={{ pointerEvents: isButtonDisabled ? 'none' : 'auto' }}
                      to={`/search?query=${searchQuery}&city=${city}`}
                    >
                      <button
                        className="btn gradientbutton toggle"
                        type="button"
                        onClick={() => localStorage.setItem('Key', value)}
                        disabled={isButtonDisabled}
                      >
                        Search
                      </button>
                    </Link>
                  </span>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Search;
