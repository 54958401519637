import React from 'react'
import { Icon } from '@iconify/react';
const Index = () => {
    return (
        <div id="index">

            <div className='container' style={{ paddingTop: "50px", paddingBottom: "30px" }}>
                <div className='row  text-center' >
                    <div>
                        <p >  At <b>AccidentMATE</b>, we understand that being involved in an accident can be a traumatic and overwhelming experience. That's why we have created a platform that connects accident victims with healthcare professionals and legal experts who can provide the necessary support and guidance to help them through this difficult time.</p>
                        <p> Our network comprises experienced healthcare professionals, legal experts, and other service providers committed to delivering the best possible outcomes for accident victims. Our mission is to empower accident victims by providing them the tools and resources to make informed decisions about their healthcare and legal options.</p>
                        <p ><b>We are committed to positively impacting the lives of accident victims and their families. </b>
                             </p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Index