import React from 'react'

const Hero = () => {
  return (
    <div>
       <section id="" className="singlebloghero" >
    <div className="container">
      <div className="row py-5">
        <div className="col-lg-12 col-md-12 text-center pt-5">
          <div data-aos="zoom-out">
            <h1>Blogs</h1>
          </div>
        </div>
      </div>
    </div>
  </section>
    </div>
  )
}

export default Hero