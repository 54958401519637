import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { getAuth} from "firebase/auth";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import "./business.css";
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';
import 'firebase/database';
import firestore from '../../../firebase';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Icon } from '@iconify/react';

// Initialize Firebase app
const firebaseConfig = {
  apiKey: "AIzaSyC_eOHkIlB8eROEtcOra5RD7MmQwYYwsTY",
  authDomain: "mlc-websites.firebaseapp.com",
  databaseURL: "https://mlc-websites-default-rtdb.firebaseio.com",
  projectId: "mlc-websites",
  storageBucket: "mlc-websites.appspot.com",
  messagingSenderId: "291991765248",
  appId: "1:291991765248:web:aaceca21e0d38fcd4eb3e5",
  measurementId: "G-430Y0PMCVZ"
};
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const contactsRef = ref(db);
const auth = getAuth();

const Business = () => {
  const [ipAddress, setIpAddress] = useState({ city: 'Unknown' });
    const [data, setData] = useState();
    const [email , setemail] = useState('');
    const [password , setpassword] = useState('');
    const [data1, setData1] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const snapshot = await firestore.collection('data').get();
          const fetchedData = snapshot.docs.map(doc => doc.data());
          setData1(fetchedData);
          console.log(fetchedData)
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

        const fetchLocation = async () => {
          try {
            const response = await axios.get('https://get.geojs.io/v1/ip/geo.json');
            setIpAddress(response.data);
          console.log(response.data)
          } catch (error) {
            console.log('Error fetching location:', error);
          }
        };
        fetchLocation();
      fetchData();
    }, []);
   
    useEffect(() => {
      const listener = onValue(contactsRef, (snapshot) => {
        const data = snapshot.val();

        setData(data);
      });
      // Return a function to unsubscribe from the listener when you no longer need it
      return () => {
        listener();
      };
    }, []);

    const currentUser = auth.currentUser;
  const currentYear = new Date().getFullYear();
   
    return (
        < div>
            <section id="" className="services mt-5">
                <div className="container">
                    <div className="text-center" data-aos="fade-down">
                        <h2 className="fw-semibold text-center">Top Service Providers</h2>
                        <p className="fw-semibold fs-14">Choose from the list of highly recommended and curated list of providers</p>
                    </div>
                    <div className="row" data-aos="fade-left">
                        <div className="swiper-container swiper-initialize" data-aos="fade-up" data-aos-delay="100">
                            <div className="">
                            <Swiper
                            slidesPerView={3}
                            breakpoints={{

                              280: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                              },
                               
                                400: {
                                  slidesPerView: 1,
                                  spaceBetween: 20,
                                },
                                768: {
                                  slidesPerView: 3,
                                  spaceBetween: 40,
                                },
                                1024: {
                                  slidesPerView: 3,
                                  spaceBetween: 50,
                                },
                              }}
                                navigation={true}
                                modules={[Pagination, Navigation]}
                                className="mySwiper"
                            >


                                {data1 && Object.values(data1).slice(0,15).map((item) => (
                              
                                (item.paidPromotion)==false?(
                                 
                          
                                  <SwiperSlide><div className="swiper-slide business">
                                     <small className='small1'>{item.primarySpecialization}</small><br />
                        {item.otherSpecializations=="NA"? <p className='small2' style={{visibility:"hidden"}}>item.otherSpecializations</p>:item.otherSpecializations==""? <small className='small2' style={{visibility:"hidden"}}>item.otherSpecializations</small>: <small className='small2'>{item.otherSpecializations.map(item=><small  className=''>{item}<br /></small>)}</small>}

<p className="mb-2 name-red"><Icon icon="bi:person" color="red " width="18" height="18" /> {item.firstName} {item.middleName} {item.lastName}</p>
                     {(item.practiceStartedIn==""||item.practiceStartedIn=="NA"||item.practiceStartedIn=="na" || item.practiceStartedIn==null)? <p className="mb-2" style={{visibility:"hidden"}}> <b>NA </b></p> : <p className="mb-2"><Icon icon="mdi:work-outline" color="#424242" width="16" height="16" /> <b>{currentYear- item.practiceStartedIn}+ </b> Years of Experience</p>}
                          <p className="mb-2"><Icon icon="solar:phone-outline" color="#424242" width="16" height="16" /> {item.phone}</p>
                          
                          <p className="mb-2 " style={{height:"40px"}} ><Icon icon="la:address-card" color="#424242" width="16" height="16" /> {item.contactAddress}</p>
                          <p className="mb-2 "> <Icon icon="mdi:home-city-outline" color="#424242" width="16" height="16" /> {item.city}, {item.state}</p>
                            <div className="rating-box-business">
                              <div className="stars">
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                              </div>
                            </div>
                          
                              </div>
                              </SwiperSlide>
                                ):null
                              
                                 ))}
                            </Swiper>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="modal fade " id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog ">
                  <div className="modal-content ">
                    <div className="modal-header">
                      <h5 className="modal-title" id="staticBackdropLabel"><b>Sign Up</b></h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body customclass d-flex justify-content-center align-items-center text-center">
                    <div className="">
                      <br/>
                      <input className="custominput1" type="Name" placeholder="Full Name" >
                      </input>
                      <br/><br/>
                      <input className="custominput1" type="email" placeholder="Email" 
                      onChange={(e)=>{setemail(e.target.value)}}>
                      </input>
                      <br/><br/>
                      <input className="custominput1" type="password" placeholder="Password" 
                      onChange={(e)=>{setpassword(e.target.value)}}>
                      </input>
                      <br/><br/>
                      <button className='custombutton mb-3' >Sign-up</button>
                    </div>
                    </div>
                    <div className="modal-footer">
                   
                    </div>
                  </div>
                </div>
              </div>
        </section >
      </div >
    
  )
}

export default Business