import React from 'react'
import { useState, useEffect } from 'react';
import Search from '../Search/Search'
import "./newhero.css";
import { Link } from 'react-router-dom';
import client from '../../../sanity';

const Newhero = () => {
  const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          const query = '*[_type == "mainpageheading"]'; 
          const result = await client.fetch(query);
          setData(result);
         
        };
    
        fetchData();
      }, []);

  return (
    <div className='newhero'>
      <div className='container flex-column pt-5 pb-5'>
        <div className='row'>
            <div className='searchbar col-lg-12 col-md-12 col-sm-12  '>
           

                <h1><b>Been in an accident? <br /> We’re here to help!</b></h1>
               
                <Search />
                <div className='hero__link'>
                 <div><span className='hero__span'>Popular :</span></div>
                 <Link to="/singlepageuser">
                <Link className='hero__sublink' to="/popular-chiropractors">Chiropractors </Link>
                <Link className='hero__sublink' to="/popular-attorneys"> Personal Injury Attorneys</Link>
                <Link className='hero__sublink' to="/popular"> More..</Link>
                </Link>
                </div>
            </div>
            <div className='col-md-6 pb-5'>
               
            </div>
        </div>
      </div>
    </div>
  )
}

export default Newhero
