import React from 'react'
import "./single.css";
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import Newsletter from '../../Components/Newsletter/Newsletter'
import Cardnew from './Cardnew';
import { useState, useEffect } from 'react';
import cities from 'cities';
import axios from 'axios';
import { set } from 'firebase/database';

const Single = () => {

  const [searchQuery, setSearchQuery] = useState('');
  const [city, setCity] = useState('');
  const [items, setItems] = useState();
  const [ipAddress, setIpAddress] = useState({ city: 'Unknown' });
  const [data, setData] = useState([]);


  const fetchCaliforniaCities = async () => {
    try {
      const response = await axios.get(
        'https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json'
      );
      setData(response.data);
    } catch (error) {
      console.error('Error fetching California cities:', error);
    }
  };

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await axios.get('https://get.geojs.io/v1/ip/geo.json');
        setIpAddress(response.data);
      } catch (error) {
        console.log('Error fetching location:', error);
      }
    };
    fetchLocation();
    fetchCaliforniaCities();
  }, []);




  const country = [...new Set(data.map((item) => (
    (item.subcountry == "California") ?
      item.name :
      null
  )))]





  return (
    <div className=''>
      <Navbar />
      <div className='container' style={{ marginTop: "60px", marginBottom: "30px" }} onChange={(e) => setCity(e.target.value)}>
        <div className='row '>


          <div className='col-lg-3   '>


            <div className='single pt-5 d-flex  align-items-center flex-column'>

              <div class="selectWrapper">
                <select class="selectBox" onChange={(e) => setSearchQuery(e.target.value)}>
                  <option selected disabled>Popular</option>
                  <option value="Doctor">Doctor</option>
                  <option value="Attorney">Attorney</option>
                  <option value="Chiropractor">Chiropractor</option>
                  {/* <option value="network">Network</option>
                  <option value="body">Body</option>
                  <option value="shop">Shop</option> */}
                </select>
              </div>
           

           

              <div class="selectWrapper">
                <select class="selectBox" onChange={(e) => setItems(e.target.value)}>
                <optgroup label="California">
                <option value="1" selected disabled>Select City</option>
                  {country.map((city, index) => (
                    <option key={index} value={city}>
                      {city}
                    </option>
                  ))}
                      </optgroup>
                </select>


              </div>


              <br />
            </div>
          </div>
          <div className='col-lg-9'>
            <div className='single'>
              <Cardnew searchQuery={searchQuery} items={items} />
            </div>
          </div>
        </div>
      </div>
      <Newsletter />
      <Footer />
    </div>
  )
}

export default Single
