import React, { useState, useEffect } from 'react';
import { getAuth, createUserWithEmailAndPassword, sendPasswordResetEmail, sendEmailVerification, signInWithEmailAndPassword } from "firebase/auth";
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';
import firestore from '../../firebase';
import { Icon } from '@iconify/react';
import { toast } from 'react-toastify';



const firebaseConfig = {
  apiKey: "AIzaSyC_eOHkIlB8eROEtcOra5RD7MmQwYYwsTY",
  authDomain: "mlc-websites.firebaseapp.com",
  databaseURL: "https://mlc-websites-default-rtdb.firebaseio.com",
  projectId: "mlc-websites",
  storageBucket: "mlc-websites.appspot.com",
  messagingSenderId: "291991765248",
  appId: "1:291991765248:web:aaceca21e0d38fcd4eb3e5",
  measurementId: "G-430Y0PMCVZ"
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const contactsRef = ref(db);
const auth = getAuth();



const Cardnew = (props) => {

  const [data, setData] = useState([]);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [data1, setData1] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await firestore.collection('data').get();
        const fetchedData = snapshot.docs.map(doc => doc.data());
        setData1(fetchedData);
    
        console.log(fetchedData)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [props.city, props.searchQuery]);

  const currentYear = new Date().getFullYear();

  



  const currentUser = auth.currentUser;
  console.log(currentUser)
  console.log(props.items);
  console.log(props.searchQuery)
  return (
    <div>
      < div>
        <section id="services" className="services pt-5">
          <div className="container">
            <div className="" data-aos="fade-left">
              <div className="swiper-container swiper-initialize " data-aos="fade-up" data-aos-delay="100">
                <div className=' row '>
                  {data1 && Object.values(data1).map((item) => (
                    (!props.items && !props.searchQuery) ? (<div className="swiper-slide cardnewwidth">
                      <div className="icon-box" data-aos="zoom-in" data-aos-delay="50" >

                        <small className='small1'>{item.primarySpecialization}</small>
                        {item.otherSpecializations=="NA"? <p  style={{visibility:"hidden" , height:"35px"}}>item.otherSpecializations</p>:item.otherSpecializations==""? <p  style={{visibility:"hidden",  height:"35px"}}>item.otherSpecializations</p>: <small className='small2'>{item.otherSpecializations.map(item=><small  className=''>{item}<br /></small>)}</small>}
                           


                        {currentUser === null ? (
                          <div className="non_blur_item">
                        <p  className="name-red mb-2"><Icon icon="bi:person" color="red" width="18" height="18" /> {item.firstName} {item.middleName} {item.lastName}</p>
                         {(item.practiceStartedIn==""||item.practiceStartedIn=="NA"||item.practiceStartedIn=="na" || item.practiceStartedIn==null)? <p className="mb-2" style={{visibility:"hidden"}}> <b>NA </b></p> : <p className="mb-2"><Icon icon="mdi:work-outline" color="#424242" width="16" height="16" /> <b>{currentYear- item.practiceStartedIn}+ </b> Years of Experience</p>}
                          <p className="mb-2"><Icon icon="solar:phone-outline" color="#424242" width="16" height="16" /> {item.phone}</p>
                       
                          <p className="mb-2 " style={{height:"40px"}} ><Icon icon="la:address-card" color="#424242" width="16" height="16" /> {item.contactAddress}</p>
                          <p className="mb-2 "> <Icon icon="mdi:home-city-outline" color="#424242" width="16" height="16" /> {item.city}, {item.state}</p>
                            <div className="rating-box">
                              <div className="stars">
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                              </div>
                            </div>
                            <a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="btn new">View details</a>
                          </div>) : (<div className='non_blur_item'>
                          <p  className="name-red mb-2"><Icon icon="bi:person" color="red" width="18" height="18" /> {item.firstName} {item.middleName} {item.lastName}</p>
                           {(item.practiceStartedIn==""||item.practiceStartedIn=="NA"||item.practiceStartedIn=="na" || item.practiceStartedIn==null)? <p className="mb-2" style={{visibility:"hidden"}}> <b>NA </b></p> : <p className="mb-2"><Icon icon="mdi:work-outline" color="#424242" width="16" height="16" /> <b>{currentYear- item.practiceStartedIn}+ </b> Years of Experience</p>}
                          <p className="mb-2"><Icon icon="solar:phone-outline" color="#424242" width="16" height="16" /> {item.phone}</p>
                        
                          <p className="mb-2 " style={{height:"40px"}} ><Icon icon="la:address-card" color="#424242" width="16" height="16" /> {item.contactAddress}</p>
                          <p className="mb-2 "> <Icon icon="mdi:home-city-outline" color="#424242" width="16" height="16" /> {item.city}, {item.state}</p>
                            <div className="rating-box">
                              <div className="stars">
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                              </div>
                            </div>
                           
                          </div>
                        )}
                      </div>
                    </div>) : (props.items == item.city && props.searchQuery == (item.category)) ? (<div className="swiper-slide cardnewwidth">
                      <div className="icon-box" data-aos="zoom-in" data-aos-delay="50" >
                        <small className='small1'>{item.primarySpecialization}</small>
                        {item.otherSpecializations=="NA"? <p  style={{visibility:"hidden" , height:"35px"}}>item.otherSpecializations</p>:item.otherSpecializations==""? <p  style={{visibility:"hidden",  height:"35px"}}>item.otherSpecializations</p>: <small className='small2'>{item.otherSpecializations.map(item=><small  className=''>{item}<br /></small>)}</small>}
                        <h3>{item.Name}</h3>
                        {currentUser === null ? (
                          <div className="non_blur_item">
                           <p  className="name-red mb-2"><Icon icon="bi:person" color="red" width="18" height="18" /> {item.firstName} {item.middleName} {item.lastName}</p>
                            {(item.practiceStartedIn==""||item.practiceStartedIn=="NA"||item.practiceStartedIn=="na" || item.practiceStartedIn==null)? <p className="mb-2" style={{visibility:"hidden"}}> <b>NA </b></p> : <p className="mb-2"><Icon icon="mdi:work-outline" color="#424242" width="16" height="16" /> <b>{currentYear- item.practiceStartedIn}+ </b> Years of Experience</p>}
                          <p className="mb-2"><Icon icon="solar:phone-outline" color="#424242" width="16" height="16" /> {item.phone}</p>
                         
                          <p className="mb-2 " style={{height:"40px"}} ><Icon icon="la:address-card" color="#424242" width="16" height="16" /> {item.contactAddress}</p>
                          <p className="mb-2 "> <Icon icon="mdi:home-city-outline" color="#424242" width="16" height="16" /> {item.city}, {item.state}</p>
                            <div className="rating-box">
                              <div className="stars">
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                              </div>
                            </div>
                            <a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="btn new">View details</a>
                          </div>) : (<div className='non_blur_item'>
                          <p  className="name-red mb-2"><Icon icon="bi:person" color="red" width="18" height="18" /> {item.firstName} {item.middleName} {item.lastName}</p>
                           {(item.practiceStartedIn==""||item.practiceStartedIn=="NA"||item.practiceStartedIn=="na" || item.practiceStartedIn==null)? <p className="mb-2" style={{visibility:"hidden"}}> <b>NA </b></p> : <p className="mb-2"><Icon icon="mdi:work-outline" color="#424242" width="16" height="16" /> <b>{currentYear- item.practiceStartedIn}+ </b> Years of Experience</p>}
                          <p className="mb-2"><Icon icon="solar:phone-outline" color="#424242" width="16" height="16" /> {item.phone}</p>
                         
                          <p className="mb-2 " style={{height:"40px"}} ><Icon icon="la:address-card" color="#424242" width="16" height="16" /> {item.contactAddress}</p>
                          <p className="mb-2 "> <Icon icon="mdi:home-city-outline" color="#424242" width="16" height="16" /> {item.city}, {item.state}</p>
                            <div className="rating-box">
                              <div className="stars">
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                              </div>
                            </div>
                   
                          </div>
                        )}
                      </div>
                    </div>)

                      : <>

                      </>
                  ))}
                </div>
              </div>
            </div>
          </div>




     


      
        </section >
      </div >
    </div>
  )
}

export default Cardnew

