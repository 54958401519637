import React from 'react'

const Hero = () => {
  return (
    <section id="" class="inner-frame py-5 newshero" >
    <div class="container">
        <div class="row py-5">
            <div class="col-lg-12 col-md-12 text-center pt-5">
                <div data-aos="zoom-out">
                    <h1>Blogs</h1>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default Hero
