import React, { useEffect, useState } from 'react';
import client from '../../../sanity';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';

const Featured = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const query = '*[_type == "post"]{..., "mainImageUrl": mainImage.asset->url}'; // Include "mainImageUrl" field to materialize the URL

      try {
        const result = await client.fetch(query);
        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };



    fetchData();
  }, []);




  if (!data) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <section id="featured" className="featured">
        <div className="container">
          <div className="text-center" data-aos="fade-down">
            <h2 className="fw-semibold text-center  mb-4">Information that can help you in difficult times. </h2>
          </div>
          <div className="row" data-aos="fade-left">

            {
              data.slice(0, 3).map((item, index) => (
                <div className="col-lg-4 col-md-4"  >
                  <Link target="_blank" to={`/news/${item.slug.current}`} onClick={() => localStorage.setItem('itemId', index)}>
                    <div className="member h-100" data-aos="zoom-in" data-aos-delay="100">
                      <div className="pic"><img src={item.mainImageUrl} className="img-fluid" alt="Blog image1" />
                      </div>
                      <div className="member-info">
                        <h4>{item.title}</h4>
                        <br />

                        <Link target="_blank" to={`/news/${item.slug.current}`} >Read More <Icon icon="bi:arrow-right-short" color='#e33b3f' /></Link>
                        <div className='' style={{ display: "none" }}>
                        </div>
                      </div>
                    </div>
                  </Link>

                </div>
              ))
            }

          </div>
          <div className="text-center mt-5">
            <Link className="btn  view-btn px-5" to="/news" style={{ color: "white", border: "none" }}>View All Blogs</Link>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Featured