import React from 'react';

const MyFormComponent = () => {
  return (
    <div>

        <iframe className='landing-frame'
        src="https://share.hsforms.com/1zyYHT3njQEu92rmqSzUlDAd1yea"
        width="100%"
       border="2px"
        frameBorder="0"
        style={{ border: 0 }}
      />
    </div>
  );
};

export default MyFormComponent;
