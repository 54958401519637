import React from 'react'

const Hero = () => {
    return (
        <div>
            <section id="hero" className="inner-frame contacthero py-5">
                <div className="container">
                    <div className="row py-5">
                        <div className="col-lg-12 col-md-12 text-center pt-5">
                            <div data-aos="zoom-out">
                                <h1>Contact Us</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Hero