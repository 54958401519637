import React from 'react'

const Banner = () => {
  return (
    <div className='index' style={{marginTop:"80px"}}>
            <div className='container'>
                    <div className='row'>
                        <div className='col-md-8 d-flex justify-content-center align-items-center flex-column about__banner' style={{ height:"400px"}}>
                        <h1> <b>About Us </b></h1>
                        <p style={{color:"black", width:"500px"}}>
                            We started <b>AccidentMATE</b> to help accident victims navigate  complex healthcare and legal systems.   Our goal is to bring together various stakeholders of the medico-legal systems to provide a comprehensive and coordinated approach to assisting accident victims.</p>
                        </div>
                    </div>
            </div>
    </div>
  )
}

export default Banner