
import React from 'react'
import ReactModal from 'react-modal';
import "../Modall/modall.css";
import modal_logo from "../../assets/img/logo.png"

const Modallerror = ({ closeModal }) => {
    return (
        <div>

            <ReactModal isOpen={true} style={{ overlay: { height: '310px', width: '500px', top: "15%", left: "25%", backgroundColor: "#008bd0", border: "none" } }} onRequestClose={closeModal}>
                <div className='modal_center'>
                    <img className='img-fluid modall_img' src={modal_logo}></img><br />
            
                    <h3 className='text-center fw-bold'>Thankyou for contacting us <br /> Our team will contact you within 24 hours</h3>

                    <button className='btn btn-primary text-center mt-3' onClick={closeModal}>Close</button>
                </div>
            </ReactModal>
        </div>
    )
}

export default Modallerror
